.navbar {
    width: 100%;
    position: fixed; /* Fixed position initially */
    top: 50px;
    left: 0;
    background-color: transparent; 
    /* backdrop-filter: blur(10px);  */
    z-index: 1000;
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.navbar.scrolled {
    position: absolute; /* Switch to absolute position after scrolling */
}

.navbar-content {
    display: flex;
    justify-content: center; /* Center all items */
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    
}

.navbar-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.navbar-logo img {
    max-height: 100px;
    width: auto;
    display: block;
    transition: all 0.3s ease;
}

.navbar-items {
    display: flex;
    /* align-items: center; */
    gap: 20px;
    position: absolute;
    right: 20px; 
}

.nav-item {
    color: #172c47;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.nav-item:hover {
    color: #efcf96;
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .navbar {
        padding: 8px 15px;
    }

    .navbar-logo img {
        max-height: 50px;
    }

    .navbar-items {
        gap: 20px;
    }
}

@media (max-width: 480px) {
    .navbar {
        padding: 5px 10px;
    }

    .navbar-logo img {
        max-height: 40px;
    }

    .navbar-items {
        gap: 15px;
    }
}
