@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  /* background-color: #f8f9fa; */
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  color: #333;
}

.product-details-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px;
}

.product-details {
  display: flex;
  gap: 200px;
}

.product-gallery {
  flex: 1;
  max-width: 650px;
}

.main-image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.main-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
  
  /* transition: transform 0.3s ease; */
}

.main-image:hover {
  transform: scale(1.05);
}

.fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fullscreen-btn:hover {
  background-color: #fff;
  transform: scale(1.1);
}

.thumbnail-container {
  display: flex;
  gap: 8px;
  margin-top: 20px;
}

.thumbnail {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0.6;
}

.thumbnail:hover, .thumbnail.active {
  opacity: 1;
  box-shadow: 0 5px 15px rgba(38, 1, 186, 0.1);
}

.product-info {
  flex: 1;
}

.product-name {
  font-family: "EB Garamond";
  font-size: 3em;
  margin-bottom: 10px;
  color: #172c47;
}

.price-tag {
  font-size: 2.2em;
  font-weight: 600;
  color: #172c47;
  margin-bottom: 20px;
}

.fit {
  font-style: italic;
  color: #172c47;
  margin-bottom: 30px;
}

.color-options, .size-options {
  margin-bottom: 30px;
}

.color-options h3, .size-options h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  font-weight: 600;
}

.color-options h3 span, .size-options h3 span {
  font-weight: 400;
  color: #172c47;
}

.color-swatches, .size-swatches {
  display: flex;
  gap: 12px;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.color-swatch:hover, .color-swatch.selected {
  transform: scale(1.2);
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #172c47;
}

.size-swatch {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.size-swatch:hover, .size-swatch.selected {
  background-color: #172c47;
  color: #efcf96;
  border-color: #172c47;
}

.product-actions {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}

.add-to-cart-btn, .wishlist-btn {
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-to-cart-btn {
  background-color: #172c47;
  color: #efcf96;
  flex: 1;
}

.add-to-cart-btn:hover {
  background-color: #333;
  transform: translateY(-3px);
}

.wishlist-btn {
  background-color: #fff;
  color: #1a1a1a;
  border: 1px solid #172c47;
  padding: 15px;
}

.wishlist-btn:hover {
  background-color: #efcf96;
  transform: translateY(-3px);
}

.product-description {
  margin-top: 40px;
  font-family: "EB Garamond";
  font-size: 1.1em;
  max-width: 100%;

}

.product-description h3 {
  font-size: 1.4em;
  margin-bottom: 15px;
  font-weight: 600;
  color: #172c47;
  font-family: "EB Garamond";

}

.product-description p {
  line-height: 1.8;
  color: #666;
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Modern browsers */
  hyphens: auto; /* Enable hyphenation for very long words */
  white-space: pre-wrap; /* Preserve whitespace and wraps */
  max-width: 100%;
}

.product-info {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .product-details {
    flex-direction: column;
  }
  
  .product-gallery, .product-info {
    max-width: 100%;
  }
}

