
@font-face {
  font-family: 'Sephir'; 
  src: url('./fonts/Sephir-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'taviraj-regular'; 
  src: url('./fonts/Taviraj-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'taviraj-bold'; 
  src: url('./fonts/Taviraj-Bold.ttf') format('opentype');
}

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Libre+Baskerville:wght@400;700&display=swap');

