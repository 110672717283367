.footer {
    background-color: #172c47;
    color: #efcf96;
    padding: 40px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    margin: 20px 0;
    flex: 1;
    min-width: 250px;
  }
  
  .footer-section h3 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .footer-section p,
  .footer-section a {
    margin: 5px 0;
    color: #cccccc;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    color: #ffffff;
  }
  
  .social-icons a {
    margin: 0 10px;
    font-size: 24px;
    color: #cccccc;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #ffffff;
  }
  
  .legal-links {
    list-style: none;
    padding: 0;
  }
  
  .legal-links li {
    margin: 10px 0;
  }
  
  .legal-links a {
    color: #cccccc;
    text-decoration: none;
  }
  
  .legal-links a:hover {
    color: #ffffff;
  }
  
  .footer-bottom {
    margin-top: 20px;
    border-top: 1px solid #444444;
    padding-top: 20px;
    font-family: 'sephir';
  }
  