.nav-item {
    position: relative;
  }
  
  .icon {
    cursor: pointer;
    font-size: 1.5rem;
    color: #172c47;
    /* color: #efcf96; */
    transition: color 0.3s ease;
  }
  
  .icon:hover {
    color: #172c47;
    
  }
  
  .search-form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 50px;
    padding: 6px 16px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  .search-bar {
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 8px 0;
    font-size: 12px;
    color: #333;
  }
  
  .search-bar::placeholder {
    color: #888;
  }
  
  .search-icon {
    background: none;
    border: none;
    color: #172c47;
    font-size: 1rem;
    cursor: pointer;
    margin-left: 8px;
  }
  
  .search-icon:hover {
    color: #efcf96;
  }
  