.productlistcontainer {
    margin-top: 40px;
    padding: 40px;
    /* background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  }
  
  .productlisttitle {
    text-align: center;
    margin-bottom: 60px;
    font-size: 36px;
    color: #172c47;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 50px;
  }
  
  .productgrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 30px;
  }
  
  .productcardwrapper {
    perspective: 1000px;
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .productcard {
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .productcardwrapper:hover .productcard {
    transform: rotateY(10deg);
  }
  
  .productimagecontainer {
    position: relative;
    overflow: hidden;
    border-radius: 12px 12px 0 0;
    
  }
  
  .productimage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .productcard:hover .productimage {
    transform: scale(1.1);
  }
  
  .productquickactions {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .productcard:hover .productquickactions {
    opacity: 1;
  }
  
  .actionicon {
    font-size: 24px;
    color: #efcf96;
    background-color: #172c47;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .actionicon:hover {
    background-color: rgba(2, 20, 44, 0.7)
  
  }
  
  .productname {
    font-size: 18px;
    color: #172c47;
    margin-bottom: 10px;
    font-family: Garamound;
    font-weight: 1000;
    letter-spacing: 1px;
  
  }
  
  .productdetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .productprice {
    font-size: 16px;
    color: #172c47;
    font-style: italic;
    font-family: Garamound;
    font-weight: 1000;
    letter-spacing: 1px;
  }
  
  .productfit {
    font-size: 14px;
    color: #172c47;
    font-family: Garamound;
    font-weight: 1000;
    letter-spacing: 1px;
  }
  
  /* Styling for the card container */
  .casualcard {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  
  /* Flex container to align content horizontally */
  .casualcontent {
  display: flex;
    width: 100%;
  }
  
  /* Styling for image container */
  .casualcontainer {
    flex: 1;
    overflow: hidden;
  }
  
  .casualimage {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  /* Styling for text content */
  .textcontent {
    flex: 2;
    padding: 16px;
  }
  