.productListContainer {
  margin-top: 40px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.productListTitle {
  text-align: center;
  margin-bottom: 60px;
  font-size: 36px;
  color: #172c47;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 50px;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  padding-left: 40px;
  padding-right: 40px;


}

.productCardWrapper {
  perspective: 1000px;
  text-decoration: none;
  color: inherit;
  display: block;

}

.productCard {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.productCardWrapper:hover .productCard {
  transform: rotateY(10deg);
}

.productImageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.productImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.productCard:hover .productImage {
  transform: scale(1.1);
}

.productQuickActions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.productCard:hover .productQuickActions {
  opacity: 1;
}

.actionIcon {
  font-size: 24px;
  color: #efcf96;
  background-color: #172c47;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.actionIcon:hover {
  background-color: rgba(145, 155, 170, 0.7);
}

.productName {
  font-size: 18px;
  color: #172c47;
  margin-bottom: 10px;
  font-family: Garamond;
  font-weight: 1000;
  letter-spacing: 1px;
}

.productDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.productPrice {
  font-size: 16px;
  color: #172c47;
  font-style: italic;
  font-family: Garamond;
  font-weight: 1000;
  letter-spacing: 1px;
}

.productFit {
  font-size: 14px;
  color: #172c47;
  font-family: Garamond;
  font-weight: 1000;
  letter-spacing: 1px;
}

.customPagination {
  margin-top: 40px;
  text-align: center;
}

.customPagination :global(.ant-pagination-item) {
  border-radius: 50%;
  background-color: #ecf0f1;
  border: none;
}

.customPagination :global(.ant-pagination-item-active) {
  background-color: #172c47;
}

.customPagination :global(.ant-pagination-item-active a) {
  color: white;
}

.casualCard {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.casualContent {
  display: flex;
  width: 100%;
}

.casualContainer {
  flex: 1;
  overflow: hidden;
}

.casualImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.textContent {
  flex: 2;
  padding: 16px;
}