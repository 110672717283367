.product-list-container {
  margin-top: 40px;
  /* padding: 40px; */
  /* background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
}

.product-list-title {
  text-align: center;
  margin-bottom: 60px;
  font-size: 36px;
  color: #172c47;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-bottom: 50px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
}

.product-card-wrapper {
  perspective: 1000px;
  text-decoration: none;
  color: inherit;
  display: block;
}

.product-card {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.product-card-wrapper:hover .product-card {
  transform: rotateY(10deg);
}

.product-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
}

.product-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.product-card:hover .product-image {
  transform: scale(1.1);
}

.product-quick-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.product-card:hover .product-quick-actions {
  opacity: 1;
}

.action-icon {
  font-size: 24px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-icon:hover {
  background-color: rgba(2, 20, 44, 0.7)

}

.product-name {
  font-size: 18px;
  color: #172c47;
  margin-bottom: 10px;
  font-weight: bold;

}

.product-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.product-price {
  font-size: 16px;
  color: #172c47;
  font-style: italic;
  /* font-weight: bold; */
}

.product-fit {
  font-size: 14px;
  color: #172c47;
}

.custom-pagination {
  margin-top: 40px;
  text-align: center;
}

.custom-pagination .ant-pagination-item {
  border-radius: 50%;
  background-color: #ecf0f1;
  border: none;
}

.custom-pagination .ant-pagination-item-active {
  background-color: #172c47;
}

.custom-pagination .ant-pagination-item-active a {
  color: white;
}


/* Styling for the card container */
.casual-card {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

/* Flex container to align content horizontally */
.casual-content {
display: flex;
  width: 100%;
}

/* Styling for image container */
.casual-container {
  flex: 1;
  overflow: hidden;
}

.casual-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Styling for text content */
.text-content {
  flex: 2;
  padding: 16px;
}
