/* Container */
.container {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Libre Baskerville', serif;
  color: #222;
}

/* Header Section */
.header {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.headerContent {
  text-align: center;
  z-index: 2;
  padding: 0 20px;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: clamp(2.5rem, 8vw, 5.5rem);
  margin-bottom: 20px;
  color: #222;
  letter-spacing: clamp(2px, 1vw, 6px);
  position: relative;
}

.title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: clamp(60px, 10vw, 100px);
  height: 2px;
  background-color: #222;
}

.subtitle {
  font-size: clamp(0.875rem, 2vw, 1rem);
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
  color: #444;
  font-family: "EB Garamond";
  padding: 0 15px;
}

/* Features Section */
.featuresSection {
  padding: clamp(40px, 5vw, 100px) clamp(20px, 4vw, 50px);
}

.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: clamp(20px, 3vw, 40px);
  max-width: 1200px;
  margin: 0 auto;
}

.featureCard {
  text-align: center;
  padding: clamp(20px, 4vw, 40px);
  background-color: #ffffff34;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}

.featureContent {
  position: relative;
  z-index: 2;
}

.featureIcon {
  font-size: clamp(1.8rem, 4vw, 2.5rem);
  margin-bottom: 20px;
  color: #d4af37;
}

.featureTitle {
  font-family: "EB Garamond";
  font-size: clamp(1.4rem, 3vw, 1.8rem);
  margin-bottom: 15px;
  color: #222;
}

.featureDescription {
  color: #444;
  line-height: 1.6;
  font-family: "EB Garamond";
  font-size: clamp(0.9rem, 2vw, 1rem);
}

/* Brand Values Section */
.brandValues {
  padding: clamp(50px, 6vw, 100px) clamp(20px, 4vw, 40px);
  background: linear-gradient(135deg, #172c47 0%, #1e3655 100%);
  color: #f4f1ec;
}

.brandValuesContent {
  max-width: 1200px;
  margin: 0 auto;
}

.brandValuesTitle {
  font-family: "Garamond";
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: clamp(30px, 4vw, 50px);
  text-align: center;
  color: #efcf96;
  letter-spacing: 2px;
}

.valuesList {
  list-style-type: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: clamp(20px, 3vw, 30px);
  font-family: "EB Garamond";
}

.valueItem {
  padding: 20px;
  font-size: clamp(1rem, 2vw, 1.1rem);
  line-height: 1.6;
  border-left: 2px solid #d4af37;
  transition: all 0.3s ease;
}

.valueItem:hover {
  background-color: rgba(212, 175, 55, 0.1);
  transform: translateX(10px);
}

/* Media Queries for Additional Adjustments */
@media (max-width: 1024px) {
  .featuresGrid {
      grid-template-columns: repeat(2, 1fr);
  }
  .header {
    height: 40vh;
}
}

@media (max-width: 768px) {
  .featuresGrid {
      grid-template-columns: 1fr;
  }
  
  .header {
      height: 60vh;
  }
  
  .valueItem:hover {
      transform: translateX(5px);
  }
}

@media (max-width: 480px) {
  .featureCard {
      padding: 20px;
  }
  .header {
    height: 60vh;
}
  
  .brandValues {
      padding: 40px 20px;
  }
  
  .valueItem {
      padding: 15px;
  }
}